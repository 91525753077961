body.game .aspects.container {
  width: 260px;
  display: inline-block;
}

body.game .aspect-container {
  height: 25px;
}

body.game .aspect-container input[type=number] {
  margin-left: 2px;
}

body.game .skills.container {
  width: 430px;
  display: inline-block;
}

body.game .skill-container {
  height: 25px;
}

body.game .status-col-1,
body.game .status-col-2,
body.game .status-col-3 {
  display: inline-block;
  width: 180px;
}

body.game .status-col-4 {
  display: inline-block;
  width: 220px;
  max-height: 70px;
  overflow-y: auto;
}

body.game .status-col-5 {
  display: inline-block;
  width: 88px;
}

body.game .active.indicator {
  color: yellow;
}

body.game .status.container.bad,
body.game .status.container.unconscious,
body.game .status.container.incapacitated,
body.game .status.container.bad .active.indicator,
body.game .status.container.unconscious .active.indicator,
body.game .status.container.incapacitated .active.indicator {
  color: orange;
}

body.game .status.container.dead,
body.game .status.container.dead .active.indicator {
  color: red;
}

body.game .status.container {
  height: 100%;
  display: flex;
}

body.game .status.container .status-col-2 .faded {
  color: gray;
}

body.game .vertical-spacer {
  height: 20px;
}

body.game .aspect-col {
  display: inline-block;
  width: 270px;
}

body.game label.aspect {
  width: 90px;
}

body.game .aspect-total,
body.game .skill-total {
  display: inline-block;
  width: 16px;
  text-align: right;
}

body.game .aspects.container input[type=number],
body.game .skills.container input[type=number] {
  width: 35px;
  margin-left: 2px;
}

body.game label.skill {
  width: 100px;
}

body.game label.location {
  width: 84px;
}

body.game label.rank,
body.game label.bleed {
  width: 48px;
}

body.game .status-col-4 button {
  width: 21px;
}

body.game .container button.aspect {
  width: 21px;
  height: 21px;
  border: 1px solid #101010;
  border-radius: 2px;
  margin: 1px;
  position: relative;
  top: 4px;
}

body.game.darkmode .container button.aspect {
  border: 1px solid lightgreen;
}

body.game button.aspect:hover {
  background-color: rgb(164,148,132);
}

body.game.darkmode button.aspect:hover {
  background-color: darkgreen;
}

body.game button.brawn,
body.game .icon.brawn {
  background: url(img/brawn.png);
}

body.game button.toughness {
  background: url(img/toughness.png);
}

body.game button.agility {
  background: url(img/agility.png);
}

body.game button.reflex {
  background: url(img/reflex.png);
}

body.game button.impression {
  background: url(img/impression.png);
}

body.game button.serenity {
  background: url(img/serenity.png);
}

body.game button.intelligence {
  background: url(img/intelligence.png);
}

body.game button.awareness {
  background: url(img/awareness.png);
}

body.game .tab-selector {
  display: inline-block;
  border: 2px solid #101010;
  border-radius: 2px;
  margin: 2px 4px 2px 4px;
  padding: 1px 4px 1px 2px;
}

body.game.darkmode .tab-selector {
  border: 2px solid lightgreen;
}

body.game .tab-selector.selected {
  background-color: rgb(164,148,132);
}

body.game.darkmode .tab-selector.selected {
  background-color: darkgreen;
}

body.game .tab-selector:hover {
  background-color: rgb(164,148,132);
  cursor: default;
}

body.game.darkmode .tab-selector:hover {
  background-color: darkgreen;
}

body.game .sub-header {
  text-align: center;
}

body.game .trait-col-0,
body.game .trait-col-1,
body.game .trait-col-2 {
  display: inline-block;
  margin: 2px;
}

body.game .trait-col-0 {
  width: 240px;
}

body.game .trait-col-1 {
  width: 270px;
}

body.game .trait-col-2 {
  width: 325px;
}

body.game .main-tab.container {
  display: flex;
}

body.game .ip.container,
body.game .actions.container {
  width: 125px;
}

body.game .actions.container button {
  width: 119px;
  margin-left: 2px;
}

body.game .traits.container {
  display: flex;
}

body.game .spell-name {
  display: inline-block;
  width: 150px;
}

body.game .spells.container .ritual.header {
  display: inline-block;
}

body.game .spells.container button {
  margin-left: 2px;
  margin-right: 3px;
}

body.game .trait-name {
  display: inline-block;
}

body.game .spell-difficulty {
  display: inline-block;
  width: 50px;
}

body.game .spell-name.header {
  margin-left: 22px;
}

body.game .known-spell .button-spacer {
  width: 23px;
  display: inline-block;
}

body.game .other.container {
  display: flex;
  flex-wrap: wrap;
}

body.game .resources.container .sub-header {
  width: 100%;
}

body.game .resources.container .container {
  width: 840px;
  margin: 2px;
}
body.game .resources .col-1 {
  width: 160px;
  display: inline-block;
  margin-left: 2px;
}

body.game .resources .col-2 {
  width: 675px;
  display: inline-block;
  margin-left: 2px;
}

body.game label.resources.rank {
  width: 110px;
}

body.game .resources textarea {
  display:block;
  width: 663px;
  margin-left: 2px;
  margin-top: 4px;
  height: 50px;
  resize: vertical;
}

body.game .npc-tools.container textarea {
  display:block;
  width: 836px;
  margin-left: 2px;
  margin-top: 4px;
  height: 50px;
  resize: vertical;
}

body.game .weapons.container .header,
body.game .ammo.container .header,
body.game .armor.container .header {
  display: inline-block;
}

body.game .weapons.container .header.name,
body.game .ammo.container .header.name,
body.game .armor.container .header.name {
  width: 151px;
}

body.game .weapons.container .entry.name,
body.game .ammo.container .entry.name,
body.game .armor.container .entry.name {
  width: 147px;
}

body.game .ammo.container .header.amount {
  width: 61px;
}

body.game .ammo.container .entry.amount {
  width: 57px;
}

body.game .weapons.container .type {
  width: 115px;
}

body.game .weapons.container .skill {
  width: 110px;
}

body.game .armor.container .location {
  width: 70px;
}

body.game .weapons.container .header.reload {
  width: 70px;
}

body.game .weapons.container .header.weightRank {
  width: 77px;
}

body.game .weapons.container .header.wt {
  width: 35px;
}

body.game .weapons.container .ammo {
  width: 138px;
}


body.game .weapons.container .header.base,
body.game .weapons.container .header.ap {
  width: 48px;
}

body.game .ammo.container .header.bonus-damage,
body.game .ammo.container .header.bonus-ap {
  width: 68px;
}

body.game .ammo.container .header.bonus-range {
  width: 78px;
}

body.game .weapons.container button.attack {
  width: 50px;
  text-align: center;
}

body.game .weapons.container .entry.base,
body.game .weapons.container .entry.ap {
  width: 42px;
}

body.game .ammo.container .entry.bonus-damage,
body.game .ammo.container .entry.bonus-ap {
  width: 62px;
}

body.game .ammo.container .entry.bonus-range {
  width: 72px;
}

body.game .armor.container .header.value,
body.game .armor.container .header.cover {
  width: 48px;
}

body.game .armor.container .entry.value,
body.game .armor.container .entry.cover {
  width: 42px;
}

body.game .weapons.container .header.thrown,
body.game .weapons.container .header.two-handed,
body.game .weapons.container .header.brawn.icon {
  width: 20px;
  margin-left: 4px;
  margin-right: 5px;
}

body.game .ammo.container .header,
body.game .ammo.container .entry {
  margin-right: 5px;
}

body.game .ammo.container .header.delete {
  margin-right: 0
}

body.game .weapons.container input[type="checkbox"] {
  width: 22px;
}

body.game .armor.container input[type="checkbox"] {
  width: 22px;
  margin-left: 20px;
}

body.game .weapons.container .header.range {
  width: 60px;
}

body.game .weapons.container .entry.range {
  width: 52px;
}

body.game .weapons.container .header.attack {
  width: 30px;
  text-align: center;
}

body.game .weapons.container button,
body.game .ammo.container button,
body.game .armor.container button{
  margin-left: 5px;
  margin-right: 5px;
}

body.game .weapons.container .header.delete,
body.game .ammo.container .header.delete,
body.game .armor.container .header.delete {
  width: 30px;
}

body.game .header.icon.brawn {
  height: 18px;
  background-repeat: none;
}

body.game .ip.container label {
  width: 85px;
}

body.game .ip.container label.short-label {
  width: 60px;
}

body.game .ip.container input {
  width: 50px;
}

body.game .notes textarea {
  margin: 2px;
  width: calc(100% - 10px);
  height: 400px;
}

body.game textarea {
  resize: none;
}

body.game .weapons .range.block,
body.game .weapons .ammo.block {
  margin-left: 30px;
  margin-right: 3px;
  display: inline-block;
}

body.game .skills.container .aspect.rare {
  opacity: 0.4;
}

body.game button.warning {
  background-color: rgb(255, 91, 91);
}

body.game.darkmode button.warning {
  background-color: darkred;
}

body.game .description {
  display: inline-block;
  width: 610px;
}