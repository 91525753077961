:root { /* Colors specific to lightmode and darkmode */
  --LMBackground: rgb(248, 232, 216);
  --DMBackground: black;
  --LMText: #101010;
  --DMText: lightgreen;
  --LMShading: rgb(216,200,178);
  --DMShading: #102010;
  --LMHover: rgb(164,148,132);
  --DMHover: darkgreen;
  --Warning: rgb(200,0,0);
}

body.dashboard {
  margin: 0;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--LMBackground);
  background: none;
  color: var(--LMText);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

body.dashboard.darkmode {
  background-color: var(--DMBackground);
  color: var(--DMText);
}

body.dashboard button {
  border: 2px solid var(--LMText);
  border-radius: 3px;
  background-color: var(--LMShading);
  color: var(--LMText);
  margin-bottom: 2px;
}

body.dashboard.darkmode button {
  background-color: var(--DMShading);
  color: var(--DMText);
  border-color: var(--DMText);
}

body.dashboard button.warning {
  background-color: var(--Warning);
}

body.dashboard .gameList .headers {
  font-weight: bold;
}

body.dashboard .gameList .gameContainer {
  margin-bottom: 5px;
}

body.dashboard .gameList .game {
  vertical-align: top;
  display: inline-block;
  border: 1px solid var(--LMText);
  border-radius: 3px;
  margin-right: 3px;
}

body.dashboard.darkmode .gameList .game {
  border-color: var(--DMText);
}

body.dashboard .gameList .gameButtonContainer {
  display: inline-block;
}

body.dashboard .gameList .gameButtonContainer .gameButton {
  display: block;
}

body.dashboard .gameList .gameName {
  display: inline-block;
  vertical-align: top;
  width: 200px;
}

body.dashboard .gameList .players {
  display: inline-block;
  vertical-align: top;
  width: 200px;
}

body.dashboard .gameList .creationDate {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}

body.dashboard .gameList .lastAccess {
  display: inline-block;
  vertical-align: top;
  width: 100px;
}

body.dashboard .gameList .gm {
  display: inline-block;
  width: 80px;
}

body.dashboard .displayName,
body.dashboard .playerName {
  display: inline-block;
}

body.dashboard .dialogue-container {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -50%);
}

body.dashboard .dialogue-container::before {
  content: "";
  position: absolute;
  top: -50vmax;
  left: -50vmax;
  bottom: -50vmax;
  right: -50vmax;
  background-color: #222d;
  z-index: -1;
}

body.dashboard .dialogue-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--LMBackground);
  border: 2px solid var(--LMText);
  z-index: -1;
}

body.dialogue .close-button,
body.dialogue.darkmode .close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5em;
  border: none;
  background: none;
}

body.dashboard.darkmode .dialogue-container::after {
  background-color: var(--DMBackground);
  border: 2px solid var(--DMText);
}




/* Sign Up Page */

.invalid {
  border: 1px solid red;
}