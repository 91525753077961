:root { /* Colors specific to lightmode and darkmode */
  --LMBackground: rgb(248, 232, 216);
  --DMBackground: black;
  --LMText: #101010;
  --DMText: lightgreen;
  --LMShading: rgb(216,200,178);
  --DMShading: #102010;
  --LMHover: rgb(164,148,132);
  --DMHover: darkgreen;
}

body.game *[onclick]:not(:disabled), body.game .cursor-pointer:not(:disabled), body.game button:not(:disabled) {
  cursor: pointer;
}

body.game  {
  margin: 0;
  padding: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--LMBackground);
  color: var(--LMText);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
}

body.game.darkmode {
  background-color: var(--DMBackground);
  color: var(--DMText);
}

body.game code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body.game input,
body.game button,
body.game textarea,
body.game select {
  background-color: var(--LMShading);
  color: var(--LMText);
  border: 1px solid var(--LMText);
  border-radius: 2px;
}

body.game.darkmode input,
body.game.darkmode button,
body.game.darkmode textarea,
body.game.darkmode select {
  background-color: var(--DMShading);
  color: var(--DMText);
  border: 1px solid var(--DMText);
  border-radius: 2px;
}

body.game button:hover {
  background-color: var(--LMHover);
}

body.game.darkmode button:hover {
  background-color: var(--DMHover);
}

body.game input[type=number]:not(:disabled)::-webkit-inner-spin-button, 
body.game input[type=number]:not(:disabled)::-webkit-outer-spin-button {  
   opacity: 1;
}



body.game ::-webkit-scrollbar-track {
	background-color: var(--LMShading);
}

body.game.darkmode ::-webkit-scrollbar-track {
	background-color: var(--DMShading);
}
body.game ::-webkit-scrollbar {
	width: 12px;
	height: 12px;
}

body.game ::-webkit-scrollbar-thumb {
	border-radius: 10px;
	background-color: var(--LMHover);
}

body.game.darkmode ::-webkit-scrollbar-thumb {
	background-color: var(--DMHover);
}

body.game .chat-panel {
  max-height: 98vh;
  width: 200px;
  overflow-y: auto;
}

body.game .chat-entry i {
  color: #888;
  font-size: 0.7rem;
}

body.game .initiative-panel {
  text-align: left;
}

body.game .player-status.online {
  color: green;
}

body.game .player-status.offline {
  color: #888;
}

body.game .game-notes {
  width: 100%;
  resize: vertical;
}

body.game input[type=number] {
  width: 40px;
}

body.game .container {
  border: 2px solid var(--LMText);
  border-radius: 3px;
  padding-bottom: 3px;
  margin: 1px;
}

body.game.darkmode .container {
  border: 2px solid var(--DMText);
}

body.game .character.container.my-turn,
body.game .character.container.my-turn .container {
  border-color: yellow;
}

body.game .character.container.over-budget,
body.game .character.container.over-budget .container {
  border-color: red;
}

body.game .character.container {
  padding: 3px;
  margin-bottom: 20px;
  width: 855px;
}

body.game label {
  display: inline-block;
}

body.game .column {
  display: inline-flex;
  flex-flow: column nowrap;
}

body.game .column.right {
  align-items: flex-start;
}

body.game .column.center {
  align-items: center;
  text-align: center;
}

body.game .column.left {
  align-items: flex-start;
  text-align: left;
}

body.game .chat-column {
  width: 220px;
}

body.game .players-column {
  width: 220px;
}

body.game .characters-column {
  width: 860px;
}

body.game .players-column .header {
  font-weight: bold;
  margin-bottom: 10px;
}

body.game .players-column .players.header,
body.game .players-column .initiative.header {
  margin-top: 10px;
}

body.game .players-column .initiative.header button {
  margin-left: 5px;
}

body.game .players-column .initiative-row div {
  display: inline-block;
}

body.game .players-column .initiative-row.hidden {
  display: none;
}

body.game .players-column .inititive-row .pointer~.initiative.value {
  font-weight: bold;
}

body.game .players-column .initiative-row .initiative.value {
  margin-left: 5px;
  margin-right: 5px;
}

body.game .players-column .initiative-row .pointer {
  margin-left: -12px;
  font-weight: bold;
}

body.game .initiative-row.cursor-pointer .initiative.remove-button button {
  width: 20px;
}

body.game .initiative-row.current .initiative.value,
body.game .initiative-row.current .name {
  font-style: italic;
}

body.game .initiative.change-button .button-spacer {
  width: 21px;
}


body.game .chat-entry .test {
  border: 1px solid var(--LMText);
}

body.game.darkmode .chat-entry .test {
  border: 1px solid var(--DMText);
}

body.game .chat-entry .test div {
  text-align: center;
}

body.game .chat-entry .name {
  background-color: var(--LMHover);
  font-weight: bold;
}

body.game.darkmode .chat-entry .name {
  background-color: var(--DMHover);
}

body.game .chat-entry .action {
  padding-bottom: 2px;
  margin-bottom: 2px;
  font-style: italic;
  background-color: var(--LMHover);
}

body.game.darkmode .chat-entry .action {
  background-color: var(--DMHover);
}

body.game .chat-entry .weapon-block,
body.game .chat-entry .spell-options {
  background-color: var(--LMShading);
}

body.game.darkmode .chat-entry .weapon-block,
body.game.darkmode .chat-entry .spell-options {
  background-color: var(--DMShading);
}

body.game .chat-entry .hand-header.divider {
  border-top: 1px solid var(--LMText);
}

body.game.darkmode .chat-entry .hand-header.divider {
    border-top: 1px solid var(--DMText);
}

body.game .chat-entry .result-line,
body.game .chat-entry .damage {
  font-weight: bold;
}

body.game .chat-entry .test .critical {
  font-weight: bold;
  color: red;
}

body.game .chat-compose {
  width: calc(100% - 10px);
  resize: vertical;
  margin-top: 5px;
}

body.game .dialogue-container {
  position: fixed;
  top: 50%;
  left: 50%;
  padding: 50px;
  transform: translate(-50%, -50%);
}

body.game .dialogue-container::before {
  content: "";
  position: absolute;
  top: -50vmax;
  left: -50vmax;
  bottom: -50vmax;
  right: -50vmax;
  background-color: #222d;
  z-index: -1;
}

body.game .dialogue-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: var(--LMBackground);
  border: 2px solid var(--LMText);
  z-index: -1;
}

body.game.darkmode .dialogue-container::after {
  background-color: var(--DMBackground);
  border: 2px solid var(--DMText);
}

body.game .dialogue-container .wide {
  width: 150px;
}

body.game .close-button,
body.game.darkmode .close-button {
  position: absolute;
  top: 0;
  right: 0;
  margin: 0.5em;
  border: none;
  background: none;
}

body.game .tooltip-container {
  position: relative;
  overflow: visible;
}

body.game .tooltip {
  padding: 6px;
  border-radius: 4px;
  background: #2f2f2fdf;
  color: #eeeeee;
  font-size: 15px;
  position: absolute;
  text-align: center;
}

body.game .left.tooltip {
  top: 50%;
  right: calc(100% + 6px);
  margin-left: -200px;
  transform: translateY(-50%);
}

body.game .right.tooltip {
  top: 50%;
  left: calc(100% + 6px);
  margin-right: -200px;
  transform: translateY(-50%);
}

body.game .top.tooltip {
  bottom: calc(100% + 6px);
  left: 100%;
  transform: translateX(-50%);
  margin: 0 -50% 0;
}

body.game .bottom.tooltip {
  top: calc(100% + 6px);
  left: 100%;
  transform: translateX(-50%);
  margin: 0 -50% 0;
}

body.game *:not(:hover) > .tooltip {
  display: none;
}

body.game .hidden {
  visibility: hidden;
}

body.game .pip-container {
  display: inline-flex;
  width: 5px;
  margin-left: -3px;
  margin-right: 2px;
  flex-direction: column;
  justify-content: center;
  position: relative;
  bottom: 8px;
}

body.game .pip {
  display: inline-block;
  width: 1px;
  height: 1px;
  border-width: 1px;
  border-style: solid;
  border-color: var(--LMText);
  border-radius: 3px;
  background: var(--LMText);
  margin-top: 1px;
}

body.game.darkmode .pip {
  border-color: var(--DMText);
  background: var(--DMText); 
}

body.game .collapser {
  margin-bottom: 10px;
}