:root {
    --color1: darkgreen;
    --color1-dd: #006400dd;
    --color2: #03b603;
    --link: orange;
    --link-visited: #da9007;
    --very-light: #eee;
    --light: #ccc;
    --grey: #888;
}

body:not(.game) {
    padding: 0;
    margin: 0;
    background: url("./images/wallpaper3-faded.png"), #faf5ed;
    background-repeat: repeat;
    font-family: "Philosopher", sans-serif;
    text-shadow: #0005 -3px 3px 10px;
}

/* HEADER */

body:not(.game) header {
    width: 100%;
}

body:not(.game) .main-logo {
    width: 100%;
    display: flex;
    justify-content: center;
    position: sticky;
    top: 0;
    background: linear-gradient(hsl(60 50% 20% / 0.5), hsl(60 50% 20% / 0.05)), url(./images/wallpaper3-faded.png), #faf5ed;
    z-index: 0;
}

body:not(.game) .main-logo img {
    height: 150px;
    max-width: 100%;
}

body:not(.game) .navigation {
    font-family: "Yatra One", cursive;
    width: 100%;
    height: 56px;
    overflow: visible;
    display: flex;
    justify-content: space-between;
    position: sticky;
    top: -1px;
    background: url("./images/wallpaper3-faded.png"), #faf5ed;
    box-shadow: 0px 0px 10px 1px #0008;
    z-index: 2;
}

body:not(.game) #nav-dropdown {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding-top: 56px;
    position: relative;
    min-width: 160px;
    height: 0;
    overflow: hidden;
}

body:not(.game) #nav-dropdown:hover {
    background: url("./images/wallpaper3-faded.png"), #faf5ed;
    box-shadow: 0px 0px 10px 1px #0008;
    height: 200px;
    transition: height 0.5s ease-in;
}

body:not(.game) #nav-dropdown::before {
    content: "";
    width: 20px;
    height: 20px;
    margin: 18px 30px;
    position: absolute;
    top: 0;
    right: 0;
    background: url("./images/bars-solid.svg");
    background-size: cover;
    background-repeat: no-repeat;
}

body:not(.game) #nav-dropdown > a {
    width: calc(100% - 60px);
    text-align: right;
    pointer-events: none;
}

body:not(.game) #nav-dropdown:not(:hover) > a {
    display: none;
}

body:not(.game) #nav-dropdown:hover > a {
    animation: no-click 0.2s both;
}

@keyframes no-click {
    to {pointer-events: unset;}
}

@media only screen and (min-width: 600px) {
    body:not(.game) #nav-dropdown {
        flex-direction: row;
        flex: 3;
        justify-content: space-between;
        padding-top: 0;
        min-width: none;
        height: unset;
    }

    body:not(.game) #nav-dropdown::before {
        display: none;
    }

    body:not(.game) #nav-dropdown:hover {
        background: none;
        box-shadow: none;
        height: unset;
    }

    body:not(.game) #nav-dropdown > a {
        width: unset;
        text-align: unset;
    }
    
    body:not(.game) #nav-dropdown:not(:hover) > a {
        display: initial;
    }
}

body:not(.game) .navigation a {
    text-decoration: none;
    color: var(--color1);
    font-size: 1.5rem;
    padding: 10px 30px;
    font-weight: bold;
    position: relative;
}

body:not(.game) .navigation a::after {
    content: "";
    display: block;
    width: 0;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    background-color: var(--color1);
}

body:not(.game) .navigation a:hover {
    color: #eee;
    background-color: var(--color1);
    transition: background-color 0.8s ease-out;
}

body:not(.game) .navigation a:hover::after {
    width: 100%;
    transition: width 0.6s ease-out;
}

body:not(.game) .navigation a:not(:hover) {
    color: var(--color1);
    background-color: none;
    transition: background-color 0.4s ease-out, color 0.4s 0.05s;
}

body:not(.game) .navigation a:not(:hover)::after {
    width: 0;
    transition: width 0.3s ease-out;
}

@keyframes fill-space {
    to { width: 100%; }
}

/* MAIN */

body:not(.game) main {
    padding-bottom: 3em;
    background: url("./images/wallpaper3-faded.png"), #faf5ed;
    background-repeat: repeat;
    position: relative;
    z-index: 1;
}

body:not(.game) .rotating-banner {
    width: 100%;
    height: 400px;
    position: relative;
    overflow: hidden;
    margin-bottom: 80px;
}

body:not(.game) .rotating-banner::after {
    content: "";
    position: absolute;
    top: -10px;
    left: -10px;
    bottom: 0;
    right: -10px;
    box-shadow: 0px 0px 10px 1px #0008 inset;
    pointer-events: none;
}

body:not(.game) .banner-panel {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    overflow: hidden;
}

body:not(.game) .banner-panel::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: url("./images/wallpaper3-faded.png"), #faf5ed;
    z-index: -1;
}

body:not(.game) .banner-image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
}

body:not(.game) .banner-text {
    position: absolute;
    left: 10%;
    top: 50%;
    transform: translate(0, -50%);
    padding: 50px;
    color: #eee;
    font-family: "Yatra One", cursive;
    background-color: var(--color1-dd);
    font-size: 2rem;
    text-shadow: #0005 -3px 3px 10px;
    max-width: calc(80% - 100px);
}

body:not(.game) .banner-arrow {
    border: none;
    position: absolute;
    top: 50%;
    transform: translate(0, -50%);
    color: #eee;
    font-family: "Yatra One", cursive;
    font-size: 4rem;
    text-shadow: #000 -3px 3px 10px;
    opacity: 0.4;
    background: none;
}

body:not(.game) #left-banner-arrow {
    left: 20px;
    transform: translate(0, -50%) scaleX(-1);
}

body:not(.game) #right-banner-arrow {
    right: 20px;
}

body:not(.game) .banner-arrow:hover {
    opacity: 0.9;
    cursor: pointer;
}

body:not(.game) .img-text-wrap {
    width: 100%;
}

body:not(.game) p {
    padding: 15px 30px;
    color: var(--color1);
    font-size: 18px;
}

body:not(.game) p:not(.half-width) {
    max-width: 800px;
    margin: 0 auto;
}

body:not(.game) p a {
    color: var(--link);
    text-decoration: none;
}

body:not(.game) p a:visited {
    color: var(--link-visited);
}

body:not(.game) p.background {
    background-position: center center;
    background-size: cover;
    padding: 40px 40px;
    text-shadow: #000 -1px 1px 2px;
}

body:not(.game) p.dark.background {
    color: var(--light);
}

body:not(.game) .paragraph-img {
    width: calc(100% - 60px);
    padding: 15px 30px;
    max-width: 600px;
    position: relative;
}

/* @media only screen and (min-width: 600px) {
    body:not(.game) .paragraph-img {
        margin: 0 calc(50% - 300px);
    }
} */

@media only screen and (min-width: 800px) {

    body:not(.game) .paragraph-img {
        margin: 0;
    }

    body:not(.game) p.half-width {
        width: calc(50% - 60px);
        display: inline-block;
    }

    body:not(.game) p.half-width.background {
        width: calc(50% - 120px);
    }

    body:not(.game) .paragraph-img.half-width {
        width: calc(49% - 60px);
    }

    body:not(.game) .img-text-wrap {
        display: flex;
        align-items: center;
    }

    body:not(.game) .img-text-wrap.reverse-on-big {
        flex-direction: row-reverse;
    }

    /* body:not(.game) .img-text-wrap>*:first-child {
        width: 
        padding-right: 15px;
    } */
}
/* @media only screen and (min-width: 1200px) {
    body:not(.game) .paragraph-img {
        margin: 0 calc(24% - 300px);
    }
} */

/* FOOTER */

body:not(.game) footer {
    width: calc(100% - 60px);
    padding: 30px;
    padding-top: 0;
    background: url("./images/wallpaper3-faded.png"), #faf5ed;
    background-repeat: repeat;
    position: relative;
    z-index: 1;
}

body:not(.game) .footer-divider {
    width: calc(100% - 60px);
    height: 1px;
    margin: 0 30px 50px;
    background: #0008;
}

body:not(.game) .footer-logo {
    display: flex;
    justify-content: center;
}

body:not(.game) .footer-logo a {
    width: 100%;
    max-width: 300px;
}

body:not(.game) .footer-logo img {
    width: 100%;
}

body:not(.game) .footer-links a {
    display: block;
    width: fit-content;
    margin-top: 10px;
}